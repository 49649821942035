import React from 'react';
import './wdyr';
import './libs/lexical/plugins/mention/MentionsPlugin.scss';
import './libs/lexical/plugins/native-emoji/NativeEmojiPlugin.scss';
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';

/*import * as Sentry from "@sentry/react";
//import {Integrations} from "@sentry/tracing";*/
import { BrowserRouter } from "react-router-dom";
import ability from "./ability";
import { AbilityContext } from './ability-context';
import { FiltersLocalProvider } from './hooks/useFiltersLocal';
import { FiltersProvider } from './hooks/useFiltersStorage';
import { BreakpointProvider } from "./libs/breakpoint";
import history from './libs/history';
import Aqp from './libs/mk-react-aqp';
import RootRoutes from './routes';
import './sass/master.scss';
import EmailUnconfirmed from "./ui/components2/EmailUnconfirmed/EmailUnconfirmed";
import AlertModal from "./ui/components2/AlertModal/AlertModal";
import { DebugProvider } from './ui/components2/Debug';
import ModalManager from './ui/components2/ModalManager';
import { Signin } from "./ui/components2/Signin/Signin";
import { Signup } from "./ui/components2/Signup/Signup";
import { EncourageSignup } from './ui/components2/encourageSignup/EncourageSignup';
import WarningModal from "./ui/components/warnings/WarningModal";
import { DrawerProvider } from './ui/components2/DrawerProvider';



Aqp.init(history);

const queries = {
    xs: '(max-width: 320px)',
    sm: '(max-width: 720px)',
    md: '(max-width: 1024px)',
    lg: '(max-width: 1200px)',
    or: '(orientation: portrait)'
}


const modalTypes = {
    EncourageSignup,
    EmailUnconfirmed,
    AlertModal,
    Signin,
    Signup,
    WarningModal
};

/*if (process.env.REACT_APP_SENTRY_DSN){
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        autoSessionTracking:true,
        beforeSend(event, hint) {
            if (event.exception) {
                let appendedOptions = event.user ? {
                    labelName: "Pseudo",
                    user: {
                        name: event.user.username,
                        email: event.user.email
                    }
                } : {};
                Sentry.showReportDialog({eventId: event.event_id, lang: "fr", ...appendedOptions});
            }
            return event;
        },
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            })
        ],
        tracesSampleRate: 0.02,
        ignoreErrors: ['ResizeObserver loop limit exceeded']
    });
}*/

const container = document.getElementById('root');
const root = createRoot(container);

const statusCodeErrorNoRetry = [400, 401, 403, 404, 500];


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // on ne relance pas la requete au focus de la fenetre
            retry : (count, {statusCode}) => (!statusCodeErrorNoRetry.includes(statusCode) && count < 2) // limit le retry a 3 et pas pour les errors cites
        }
    },
    queryCache: new QueryCache({
        onError: (error, query) => {
            if(error.statusCode === 403){
                window.location.href = "/"
            }
        }
    })
});


root.render(
    <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === "developmesnt" ?  <ReactQueryDevtools initialIsOpen={false} /> : null}
        <FiltersProvider>
            <FiltersLocalProvider>
                <BrowserRouter>
                    <DebugProvider>
                        <BreakpointProvider queries={queries}>
                            <AbilityContext.Provider value={ability}>
                                <ModalManager modalTypes={modalTypes}>
                                    <DrawerProvider>
                                        <RootRoutes />
                                    </DrawerProvider>
                                </ModalManager>
                            </AbilityContext.Provider>
                        </BreakpointProvider>
                    </DebugProvider>
                </BrowserRouter>
            </FiltersLocalProvider>
        </FiltersProvider>
    </QueryClientProvider>
);
import React, {Suspense, useEffect, useState} from 'react';
import {
    Route,
    Routes,
    useLocation, useNavigate, useSearchParams
} from "react-router-dom";

import qs from 'qs';
import history from './libs/history';

import MainRouter from './MainRouter';
import {LoadingSuspense} from "./ui/components/Loader";
import backButton from "./libs/backButton";
import {
    USER_TOKEN_KEY,
    verifyToken
} from "./api/public-voissa/users";
import {useQuery} from "@tanstack/react-query";
import useToken from "./hooks/token";
import Aqp from "./libs/mk-react-aqp";
import Maintenance from './ui/pages/Maintenance/Maintenance';
import BetaAccess from './ui/pages/BetaAccess/BetaAccess';
import ScrollToTop from './ui/components2/ScrollToTop';
import Cookies from "universal-cookie";
import { AB_SERVICE_USER_TESTS, fetchUserTests } from './api/public-voissa/abService';
import { isBot } from './libs/helper';



/*
const delay = async (timeInSeconds) => {
    return new Promise((resolve) => {
        setInterval(resolve, timeInSeconds * 1000);
    })
}*/


const checkSource = ()=>{

    let source = localStorage.getItem(`${process.env.REACT_APP_TOKEN_NAME}_source`) || "";

    if(!source){
        if(!localStorage.getItem(process.env.REACT_APP_TOKEN_NAME) && window.location.hash.match(/[#&]source=nouslib/)){
            localStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}_source`, "nouslib");
            source = "nouslib";
        }
        else{
            localStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}_source`, "voissa");
            source = "voissa";
        }
    }
    if(!["voissa", "nouslib"].includes(source)){
        localStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}_source`, "voissa");
        source = "voissa";
    }

    let isBackbutton = localStorage.getItem(`${process.env.REACT_APP_TOKEN_NAME}_fromdsiclaimer`) || "0";
    if(isBackbutton.toString() === "1"){
        backButton("popover", {source});
        localStorage.removeItem(`${process.env.REACT_APP_TOKEN_NAME}_fromdsiclaimer`);
    }

    if(window.location.hash.match(/[#&]hist=1/)){
        backButton("popundercallback", {source});
    }
    let newHash = window.location.hash.replace(/(.*)[#&]disc=1(.*)/, '$1$2').replace(/(.*)[#&]hist=1(.*)/, '$1$2').replace(/(.*)[#&]source=[a-z]*\b(.*)/, '$1$2');
    window.history.replaceState(null, document.title, window.location.pathname + window.location.search + newHash);
}


const AutoLogin = ({
        callback = () =>{}
    }) =>{
    const location = useLocation();
    const [token, setToken] = useToken();
    const currentToken = token;
    const navigate = useNavigate();
    const cookies = new Cookies();
    let {atoken, forced = false} = qs.parse(location.search, {ignoreQueryPrefix: true});
    if(atoken && forced){
        setToken(null);
    }
    const {isFetched, data : dataToken} = useQuery(
        [USER_TOKEN_KEY],
        ()=> verifyToken({ ephemeralToken : atoken, currentToken }, {timeout : 2000}),
        {
            enabled : !!atoken,
            onSuccess:()=>{
                localStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}_fromdsiclaimer`, "1");
                cookies.set('DISCLAIMED', 'accepted',{expire:0});
            }
        }
    );

    let url = window.origin + location.pathname + location.search + location.hash;

    let newUrl = new URL(url);
    // Supprimez le paramètre 'atoken' de l'objet URL
    /*newUrl.searchParams.delete('atoken');
    let paramsLength = new URLSearchParams(newUrl);
    let parametresString = newUrl.search;

    if(paramsLength){
        url = `${location.pathname}${parametresString}${location.hash}`;
    }
    else{
        url = `${location.pathname}${location.hash}`;
    }
    */

    let params = new URLSearchParams(newUrl.search);
    params.delete('atoken');
    params.delete('forced');

    if(params.length) {
        url = `${location.pathname}?${params}${location.hash}`;
    }
    else{
        url = `${location.pathname}${location.hash}`;
    }
    useEffect(() =>{
        callback(atoken, isFetched);
    }, [isFetched, atoken, callback]);

    useEffect(() =>{
        if(dataToken?.status && atoken){
            setToken(dataToken?.token);
            navigate(url);
        }
        else if(!dataToken?.status && atoken){
            navigate(url);
        }
    }, [dataToken, atoken, setToken, navigate, url]);


    return null;
}


const UpdateInitAqp = () =>{
    const location = useLocation();

    useEffect(() =>{
        Aqp.init({...history, location});
    }, [location])
}

const useBetaAccess = () =>{
    const {pathname} = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    if(process.env.REACT_APP_BETA_ACCESS && pathname !== "/beta"){
        const {betaAccess} = qs.parse(searchParams.toString());
        const localStorageBetaAccess = localStorage.getItem("betaAccess");
        if(betaAccess === process.env.REACT_APP_BETA_ACCESS && !localStorageBetaAccess) {
            localStorage.setItem("betaAccess", betaAccess);
        }
        if(localStorage.getItem("betaAccess") !== process.env.REACT_APP_BETA_ACCESS) window.location.href = '/beta';
        if(betaAccess) setSearchParams({}, {replace: true});
    }
};

const InterceptorBeforeRendu = () => {
    const [atoken, setAtoken] = useState(false);
    const [isFetched, setIsFetched] = useState(false);

    useBetaAccess();
    checkSource();
    const {isFetched : isFetchedUserTests} = useQuery([AB_SERVICE_USER_TESTS], ()=> fetchUserTests(), {
        cacheTime: Infinity,
        staleTime: Infinity,
        retry : false,
        enabled : !isBot
    });
    const ABTestReady = isBot || isFetchedUserTests;
    return <>
        <UpdateInitAqp />
        <AutoLogin
            callback={(token, fetched) =>{
                if(atoken !== token){
                    setAtoken(token);
                }
                if(isFetched !== fetched){
                    setIsFetched(fetched)
                }
            }}
        />

        {(!((!isFetched && !!atoken) || !atoken) || !ABTestReady) ?  <LoadingSuspense /> : <MainRouter />}
    </>
}


const RootRoutes = () => {
    return (
        <Routes>
            <Route path={"/maintenance"} element={<Maintenance />} />
            <Route path={"/beta"} element={<BetaAccess />} />
            <Route path={"*"} element={<Suspense fallback={<LoadingSuspense />}><ScrollToTop><InterceptorBeforeRendu /></ScrollToTop></Suspense>} />
        </Routes>
    )
}

export default RootRoutes;
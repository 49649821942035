import EmailUnconfirmed from "../ui/components2/EmailUnconfirmed/EmailUnconfirmed"
//import Signup from "../ui/pages/Signup";
import Signup from "../ui/components2/Signup/Signup";
import ModalEncourageSignup from "../ui/components2/encourageSignup/EncourageSignup";
import createLink from "./createLink";
import Warning from "../ui/components/warnings/Warning";
import _ from "lodash";


const fnClickCta = (data = {}) => {
    let {role = "", profile = {}, isBan = null, forbidden = {}} = data;
    let {gender = 0} = profile;

    let infos = {
        modal: null,
        to: createLink("userMeSettingsAbonnement")
    };

    let infosUpload = {...infos};
    let infoSubscription = {...infos};

    if([2,3,4].includes(_.get(data,'profile.gender'))){
        infoSubscription = {
            to : createLink("userMeSettingsCertification")
        }
    }
    else{
        infoSubscription = {
            to : createLink("subscription")
        }
    }

    // console.log(role);
    switch(role){
        case"emailUnconfirmed":
            return {
                like : {
                    modal : EmailUnconfirmed
                },
                addFriend : {
                    modal : EmailUnconfirmed
                },
                addFollow : {
                    modal : EmailUnconfirmed
                },
                createAlbum : {
                    modal : EmailUnconfirmed
                },
                createTopic : {
                    modal : EmailUnconfirmed
                },
                createArticle : {
                    modal : EmailUnconfirmed
                },
                createComment : {
                    modal : EmailUnconfirmed
                },
                createStatus : {
                    modal : EmailUnconfirmed
                },
                rooms : {
                    modal : EmailUnconfirmed
                },
                upload : {
                    modal : EmailUnconfirmed
                },
                chatWebcam : {
                    modal : EmailUnconfirmed
                },
                meetSearch : {
                    modal : EmailUnconfirmed
                },
                subscription : {
                    modal : EmailUnconfirmed
                },
                contentMember : {
                    modal : EmailUnconfirmed
                }
            };
        case"standard":
            if(isBan){
                infos = {
                    modal: Warning,
                    to: createLink("userMe")
                }
            }
            else if(![2,3,4].includes(gender)){
                infos = {
                    to: createLink("userMeSettingsCertification")
                }
            }

            infosUpload = {...infos};
            infosUpload = {...infos};

            if(forbidden.createMedia){
                infosUpload = {
                    modal: Warning,
                    to: createLink("userMe")
                }
            }

            return {
                like : infos,
                addFriend : infos,
                addFollow : infos,
                createAlbum : infos,
                createTopic : infos,
                createArticle : infos,
                createComment : infos,
                createStatus : infos,
                rooms : infos,
                upload : infosUpload,
                chatWebcam : infos,
                subscription: infoSubscription,
                contentMember : infos,
            };
        case"creator":
            if(isBan){
                infos = {
                    modal: Warning,
                    to: createLink("userMe")
                }
            }
            else if([2,3,4].includes(gender)){
                infos = {
                    to: createLink("userMeSettingsCertification")
                }
            }

            infosUpload = {...infos};

            if(forbidden.createMedia){
                infosUpload = {
                    modal: Warning,
                    to: createLink("userMe")
                }
            }

            return {
                like : infos,
                addFriend : infos,
                addFollow : infos,
                createAlbum : infos,
                createTopic : infos,
                createArticle : infos,
                createComment : infos,
                createStatus : infos,
                rooms : infos,
                upload : infosUpload,
                chatWebcam : infos,
                subscription: infoSubscription,
                contentMember : infos,
            };

        case"banned":
            return {};
        default :
            return {
                like : {
                    modal : Signup,
                    to : createLink("signin")
                },
                addFriend : {
                    modal : Signup,
                    to : createLink("signin")
                },
                addFollow : {
                    modal : Signup,
                    to : createLink("signin")
                },
                createAlbum : {
                    modal : Signup,
                    to : createLink("signin")
                },
                createTopic : {
                    modal : Signup,
                    to : createLink("signin")
                },
                createArticle : {
                    to : createLink("signin")
                },
                createComment : {
                    to : createLink("signin")
                },
                createStatus : {
                    modal : Signup,
                    to : createLink("signin")
                },
                rooms : {
                    to : createLink("signin")
                },
                upload : {
                    modal : Signup,
                    to : createLink("signin")
                },
                chatWebcam : {
                    modal : Signup,
                    to : createLink("signin")
                },
                meetSearch : {
                    modal : Signup,
                    to : createLink("signin")
                },
                subscription : {
                    modal : Signup,
                    to : createLink("signin")
                },
                contentMember : {
                    modal : ModalEncourageSignup,
                    to : createLink("signin")
                }
            };
    }
};


// createLink("charter");
export default fnClickCta;
